import { Button, SaveButton, Toolbar, useDelete, useNotify, useRedirect } from 'react-admin';

import Delete from '@material-ui/icons/Delete';
import { DeleteModal } from 'ui/DeleteModal';
import { ModalContainer } from 'ui/ModalContainer';
import styled from 'styled-components';
import { useState } from 'react';

const DeleteModalText: { [key: string]: string } = {
  ['edit-manager']: 'Вы действительно хотите удалить менеджера?',
  ['edit-hospital']: 'Вы уверены, что хотите удалить клинику?',
};

export const EditToolbar = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [deleteOne, { loading, error }] = useDelete();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const openDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const handleDelete = () => {
    closeDeleteModal();

    redirect(props.redirect, props.basePath);
    deleteOne(props.resource, props.record.id, props.record, {
      undoable: true,
      onSuccess: () => notify('Element deleted', 'info', { resource: 'post' }, true),
    });
  };

  if (error) {
    notify(error, 'info', { resource: 'post' });
  }

  const currentPage = props.page as string;

  return (
    <Root {...props}>
      <SaveButton />
      <DeleteButton
        {...props}
        label="Delete"
        onClick={openDeleteModal}
        disabled={loading}
        startIcon={<Delete />}
        variant="contained"
        size="medium"
      />
      {isDeleteModalVisible && (
        <ModalContainer closeModal={closeDeleteModal}>
          <DeleteModal text={DeleteModalText[currentPage]} onDelete={handleDelete} onClose={closeDeleteModal} />
        </ModalContainer>
      )}
    </Root>
  );
};

const Root = styled(Toolbar)`
  justify-content: space-between;
`;
const DeleteButton = styled(Button)`
  background-color: #e54d4d;
`;
