import { BackButton, EditToolbar } from '../../../ui';
import { Edit, ListProps, NumberInput, SimpleForm, TextInput, required } from 'react-admin';

import { FC } from 'react';

const checkOnlyNumber = (value: any) => {
  const reg = new RegExp('^[0-9]+$');
  return reg.test(value) ? undefined : 'Только целые числа';
};

const validateRequired = required();
const validateNumber = [required(), checkOnlyNumber];

const EditTitle = ({ record }: { record?: { name: string } }) => {
  return <span>{`${record?.name}`}</span>;
};

const HospitalEdit: FC<ListProps> = (props) => {
  return (
    <Edit {...props} title={<EditTitle />} actions={<BackButton />}>
      <SimpleForm toolbar={<EditToolbar page="edit-hospital" />}>
        <TextInput source="name" label="Наименование учреждения" validate={validateRequired} />
        <NumberInput
          source="patientsLimit"
          label="Лимит пациентов"
          validate={validateNumber}
          max={1000}
          min={1}
          step={1}
        />
        <TextInput disabled source="id" />
      </SimpleForm>
    </Edit>
  );
};

export default HospitalEdit;
