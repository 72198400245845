import {
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  ExportButton,
  List,
  ListProps,
  ReferenceField,
  SearchInput,
  TextField,
  TopToolbar,
} from 'react-admin';

import { FC } from 'react';
import { ManagerListEmpty } from './components';
import { Resources } from '../../types';

const filters = [<SearchInput source="email" alwaysOn placeholder="E-mail" />];

const TopButtons = () => (
  <TopToolbar>
    <CreateButton label="Добавить нового менеджера" />
    <ExportButton />
  </TopToolbar>
);

const ManagersList: FC<ListProps> = (props) => {
  return (
    <List {...props} filters={filters} actions={<TopButtons />} empty={<ManagerListEmpty />}>
      <Datagrid>
        <EmailField source="email" />
        <ReferenceField label="Наименование учреждения" source="hospitalId" reference={Resources.HOSPITALS}>
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="Добавлен" />
        <DateField source="updatedAt" label="Обновлен" />
        <EditButton label="Редактировать" />
      </Datagrid>
    </List>
  );
};

export default ManagersList;
