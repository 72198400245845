import { Create, FileField, FileInput, SimpleForm, required, useNotify, useRefresh } from 'react-admin';

import { API_URL } from 'utils/constants';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Resources } from 'routes/types';
import httpClient from 'libs/httpClient';
import { useCallback } from 'react';

const validateRequired = required();

const Drugs = ({ ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = useCallback(() => {
    notify('Таблица загружена');
    refresh();
  }, [notify, refresh]);

  const save = useCallback(
    async (values) => {
      try {
        const file = values.drugs.rawFile;
        const formData = new FormData();
        formData.append('drugs', file);

        const url = API_URL + '/' + Resources.DRUGS;
        await httpClient(url, { method: 'POST', body: formData });

        onSuccess();
      } catch (error: any) {
        if (error?.body?.errors) {
          return error?.body?.errors;
        } else {
          notify('Произошла ошибка загрузки');
        }
      }
    },
    [onSuccess, notify],
  );

  return (
    <Card>
      <CardContent>
        <Create resource={Resources.DRUGS} basePath={Resources.DRUGS} {...props} title="Добавьте  препараты">
          <SimpleForm save={save} redirect={false}>
            <FileInput
              source="drugs"
              label="Загрузите таблицу препаратов"
              accept=".xlsx, .xls"
              validate={validateRequired}>
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleForm>
        </Create>
      </CardContent>
    </Card>
  );
};

export default Drugs;
