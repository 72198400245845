import {
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  ListProps,
  ReferenceManyField,
  Show,
  TextField,
} from 'react-admin';

import { BackButton } from '../../../ui';
import { FC } from 'react';
import { HospitalShowEmpty } from './components';
import { Resources } from '../../types';

const EditTitle = ({ record }: { record?: { name: string } }) => {
  return <span>{`Менеджеры ${record?.name}`}</span>;
};

const HospitalShow: FC<ListProps> = (props) => {
  return (
    <Show {...props} title={<EditTitle />} actions={<BackButton />}>
      <ReferenceManyField addLabel={false} reference={Resources.MANAGERS} target="hospitalId">
        <Datagrid empty={<HospitalShowEmpty />}>
          <EmailField source="email" />
          <TextField source="id" />
          <DateField source="createdAt" label="Добавлен" />
          <DateField source="updatedAt" label="Обновлен" />
          <EditButton label="Редактировать" />
        </Datagrid>
      </ReferenceManyField>
    </Show>
  );
};

export default HospitalShow;
