import React, { ReactElement } from 'react';

import styled from 'styled-components';

type Props = {
  children: ReactElement;
  closeModal: () => void;
  minHeightForMediaQuery?: string;
};

export const ModalContainer = ({ children, closeModal, minHeightForMediaQuery = '100px' }: Props) => {
  return (
    <Root onClick={closeModal} $minHeightForMediaQuery={minHeightForMediaQuery}>
      {children}
    </Root>
  );
};

const Root = styled.div<{ $minHeightForMediaQuery: string }>`
  position: fixed;
  background: #77799850;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 99;
  overflow: auto;

  ${({ $minHeightForMediaQuery }) => `
  @media (min-height: ${$minHeightForMediaQuery}) {
    align-items: center;
  }
  `}
`;
