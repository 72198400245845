import { Admin, Login, Resource } from 'react-admin';
import {
  Drugs,
  HospitalCreate,
  HospitalEdit,
  HospitalShow,
  HospitalsList,
  ManagerCreate,
  ManagerEdit,
  ManagersList,
  Menu,
} from 'routes';

import { API_URL } from './utils/constants';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { Resources } from './routes/types';
import { Route } from 'react-router-dom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import authProvider from './libs/authProvider';
import crudProvider from '@fusionworks/ra-data-nest-crud';
import httpClient from './libs/httpClient';
import { makeStyles } from '@material-ui/core/styles';

const dataProvider = crudProvider(API_URL, httpClient);

const useStyles = makeStyles({
  main: {
    background: 'radial-gradient(farthest-corner at 50% 20%, #F5F8FF, #1562CD)',
  },
});

const LoginPage = () => {
  const classes = useStyles();
  return <Login className={classes.main} />;
};

const CustomRoutes = [<Route exact path={'/' + Resources.DRUGS} component={Drugs} />];

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      menu={Menu}
      customRoutes={CustomRoutes}
      authProvider={authProvider}
      loginPage={LoginPage}>
      <Resource
        name={Resources.HOSPITALS}
        list={HospitalsList}
        options={{ label: 'Мед. учреждения' }}
        edit={HospitalEdit}
        create={HospitalCreate}
        show={HospitalShow}
        icon={LocationCityIcon}
      />
      <Resource
        name={Resources.MANAGERS}
        list={ManagersList}
        options={{ label: 'Менеджеры' }}
        edit={ManagerEdit}
        create={ManagerCreate}
        icon={SupervisorAccountIcon}
      />
    </Admin>
  );
}

export default App;
