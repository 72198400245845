import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { TopToolbar } from 'react-admin';
import styled from 'styled-components';

type Props = {
  onClick?: () => void;
  basePath?: string;
};

const BackButtonFromManagers = ({ onClick }: Props) => {
  return (
    <TopToolbar>
      <Root>
        <ChevronLeft fontSize="small" />
        <Text onClick={onClick}>Назад</Text>
      </Root>
    </TopToolbar>
  );
};

export default BackButtonFromManagers;

const Root = styled.div`
  min-width: 64px;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color: #3f51b5;
  font-size: 0.8125rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px 5px;

  &:hover {
    background-color: #efeff1;
  }

  &:active {
    background-color: #b6c0ee;
  }
`;
const Text = styled.div`
  padding-left: 6.5px;
`;
