import {
  Button,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  List,
  ListProps,
  NumberField,
  SearchInput,
  ShowButton,
  TextField,
  TopToolbar,
  useDeleteMany,
  useListController,
  useNotify,
} from 'react-admin';
import { DeleteModal, ModalContainer } from 'ui';
import { FC, useState } from 'react';

import Delete from '@material-ui/icons/Delete';
import { HospitalListEmpty } from './components';
import styled from 'styled-components';

const filters = [<SearchInput source="name" alwaysOn placeholder="Наименование учреждения" />];

const TopButtons = () => (
  <TopToolbar>
    <CreateButton label="Добавить новое учреждение" />
    <ExportButton />
  </TopToolbar>
);

const HospitalsList: FC<ListProps> = (props) => {
  const notify = useNotify();
  const [deleteMany] = useDeleteMany();
  const controller = useListController(props);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const openDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const handleDelete = () => {
    deleteMany(props.resource, controller.selectedIds, {
      undoable: true,
      onSuccess: () => notify('Element deleted', 'info', { resource: 'post' }, true),
    });
  };

  return (
    <>
      <List
        {...props}
        bulkActionButtons={
          <DeleteButton
            label="Delete"
            onClick={openDeleteModal}
            startIcon={<Delete />}
            variant="contained"
            size="medium"
          />
        }
        title="Медицинские учреждения"
        filters={filters}
        sort={{ field: 'name', order: 'ASC' }}
        actions={<TopButtons />}
        empty={<HospitalListEmpty />}>
        <Datagrid>
          <TextField source="name" label="Наименование учреждения" />
          <NumberField source="patientsCount" label="Количество пациентов" />
          <NumberField source="patientsLimit" label="Лимит пациентов" />
          <DateField source="createdAt" label="Добавлен" />
          <DateField source="updatedAt" label="Обновлен" />
          <EditButton label="Редактировать" />
          <ShowButton label="Посмотреть менеджеров" />
        </Datagrid>
      </List>
      {isDeleteModalVisible && (
        <ModalContainer closeModal={closeDeleteModal}>
          <DeleteModal
            text="Вы уверены, что хотите удалить выбранные клиники?"
            onDelete={handleDelete}
            onClose={closeDeleteModal}
          />
        </ModalContainer>
      )}
    </>
  );
};

const DeleteButton = styled(Button)`
  color: #f44336;
  background-color: transparent;
  box-shadow: none;

  &:hover {
    background-color: rgba(244, 67, 54, 0.12);
    box-shadow: none;
  }
`;

export default HospitalsList;
