import { ListButton, TopToolbar } from 'react-admin';

import ChevronLeft from '@material-ui/icons/ChevronLeft';

type Props = {
  onClick?: () => void;
  basePath?: string;
};

const BackButton = ({ basePath, onClick }: Props) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Назад" icon={<ChevronLeft />} onClick={onClick} />
  </TopToolbar>
);

export default BackButton;
