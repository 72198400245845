import { BASE_API_URL } from 'utils/constants';

import { http } from './axios';
import {
  AdminPanelDrugsXlsApi,
  AdminPanelHospitalManagersApi,
  AdminPanelHospitalsApi,
  AdminPanelPatientsApi,
  V1AdminsApi,
} from './generated';

export const api = {
  V1AdminsApi: new V1AdminsApi(undefined, BASE_API_URL, http.axios),
  PatientsApi: new AdminPanelPatientsApi(undefined, BASE_API_URL, http.axios),
  AdminPanelDrugsXlsApi: new AdminPanelDrugsXlsApi(undefined, BASE_API_URL, http.axios),
  HospitalApi: new AdminPanelHospitalsApi(undefined, BASE_API_URL, http.axios),
  ManagerAPi: new AdminPanelHospitalManagersApi(undefined, BASE_API_URL, http.axios),
};
