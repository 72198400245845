import React from 'react';
import styled from 'styled-components';

const HospitalShowEmpty = () => {
  return (
    <Root>
      <Text>В данном медицинском учреждении менеджеры отсутствуют. Вы можете добавить их на странице "Менеджеры"</Text>
    </Root>
  );
};

export default HospitalShowEmpty;

const Root = styled.div`
  display: flex;
  justify-content: center;
`;
const Text = styled.div`
  max-width: 350px;
  padding: 30px;
  text-align: center;
  line-height: 30px;
`;
