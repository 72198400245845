import { CreateButton } from 'react-admin';
import React from 'react';
import styled from 'styled-components';

const ManagerListEmpty = () => {
  return (
    <Root>
      <Text>У Вас пока нет менеджеров ни в одном медицинском учреждении. Пожалуйста, добавьте нового менеджера</Text>
      <CreateButton label="Добавить нового менеджера" />
    </Root>
  );
};

export default ManagerListEmpty;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Text = styled.div`
  max-width: 350px;
  padding: 30px;
  text-align: center;
  line-height: 30px;
`;
