import { Button } from 'react-admin';
import Delete from '@material-ui/icons/Delete';
import styled from 'styled-components';

type Props = {
  text: string;
  onDelete: () => void;
  onClose: () => void;
};

export const DeleteModal = ({ text, onDelete, onClose }: Props) => {
  return (
    <Root>
      <Text>{text}</Text>
      <ButtonsWrapper>
        <DeleteButton label="Delete" onClick={onDelete} startIcon={<Delete />} variant="contained" size="small" />
        <CancelButton label="Cancel" onClick={onClose} variant="contained" size="small" />
      </ButtonsWrapper>
    </Root>
  );
};

const Root = styled.div`
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 1px solid #bababa;
  box-sizing: border-box;
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 50px 38px;
`;

const Text = styled.div`
  text-align: center;
  letter-spacing: -0.165px;
  margin-top: 10px;
  margin-bottom: 38px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeleteButton = styled(Button)`
  background-color: #e54d4d;

  &:hover {
    background-color: #c43030;
  }
`;

const CancelButton = styled(Button)`
  background-color: #3f51b5;
`;
