import { BackButtonFromManagers, EditToolbar } from '../../../ui';
import { Edit, ListProps, ReferenceInput, SelectInput, SimpleForm, TextInput, email, required } from 'react-admin';

import { FC } from 'react';
import { Resources } from '../../types';

const validateEmail = [required(), email()];
const validateRequired = required();

const EditTitle = ({ record }: { record?: { email: string } }) => {
  return <span>{`${record?.email}`}</span>;
};

const ManagerEdit: FC<ListProps> = (props) => {
  const history = props.history;
  const goBack = () => {
    history?.go(-1);
  };

  return (
    <Edit {...props} title={<EditTitle />} actions={<BackButtonFromManagers onClick={goBack} />}>
      <SimpleForm toolbar={<EditToolbar page="edit-manager" />}>
        <TextInput source="email" label="E-mail" validate={validateEmail} />
        <ReferenceInput
          label="Наименование учреждения"
          source="hospitalId"
          reference={Resources.HOSPITALS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={10000}
          validate={validateRequired}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput disabled source="id" />
      </SimpleForm>
    </Edit>
  );
};

export default ManagerEdit;
