import { Create, NumberInput, SimpleForm, TextInput, required } from 'react-admin';

import { BackButton } from '../../../ui';

const checkOnlyNumber = (value: any) => {
  const reg = new RegExp('^[0-9]+$');
  return reg.test(value) ? undefined : 'Только целые числа';
};

const validateRequired = required();
const validateNumber = [required(), checkOnlyNumber];

const HospitalCreate = ({ ...props }) => {
  return (
    <Create {...props} title="Добавьте медицинское учреждение" actions={<BackButton />}>
      <SimpleForm>
        <TextInput source="name" label="Наименование учреждения" validate={validateRequired} />
        <NumberInput
          defaultValue={10}
          source="patientsLimit"
          label="Лимит пациентов"
          validate={validateNumber}
          max={1000}
          min={1}
          step={1}
        />
      </SimpleForm>
    </Create>
  );
};

export default HospitalCreate;
