/* tslint:disable */
/* eslint-disable */
/**
 * Surveys Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdminDto
 */
export interface AdminDto {
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    role: AdminDtoRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    lastName: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminDtoRoleEnum {
    Patient = 'patient',
    Doctor = 'doctor',
    Admin = 'admin',
    Manager = 'manager'
}

/**
 * 
 * @export
 * @interface AdminsSignInBodyDto
 */
export interface AdminsSignInBodyDto {
    /**
     * 
     * @type {string}
     * @memberof AdminsSignInBodyDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AdminsSignInBodyDto
     */
    password: string;
}
/**
 * 
 * @export
 * @interface AuthAdminDto
 */
export interface AuthAdminDto {
    /**
     * 
     * @type {AdminDto}
     * @memberof AuthAdminDto
     */
    user: AdminDto;
    /**
     * 
     * @type {string}
     * @memberof AuthAdminDto
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof AuthAdminDto
     */
    refreshToken: string;
}
/**
 * 
 * @export
 * @interface CreateHospitalDto
 */
export interface CreateHospitalDto {
    /**
     * 
     * @type {string}
     * @memberof CreateHospitalDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateHospitalDto
     */
    patientsLimit: number;
}
/**
 * 
 * @export
 * @interface CreateHospitalManagerDto
 */
export interface CreateHospitalManagerDto {
    /**
     * 
     * @type {string}
     * @memberof CreateHospitalManagerDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHospitalManagerDto
     */
    hospitalId: string;
}
/**
 * 
 * @export
 * @interface Doctor
 */
export interface Doctor {
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    id: string;
    /**
     * 
     * @type {Array<HospitalsDoctors>}
     * @memberof Doctor
     */
    hospitalsDoctors: Array<HospitalsDoctors>;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    language: DoctorLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    password: string;
    /**
     * 
     * @type {object}
     * @memberof Doctor
     */
    notificationsSettings: object;
    /**
     * 
     * @type {DoctorNotification}
     * @memberof Doctor
     */
    notifications?: DoctorNotification;
    /**
     * 
     * @type {PatientNotification}
     * @memberof Doctor
     */
    patientNotifications?: PatientNotification;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    role: DoctorRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    deletedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorLanguageEnum {
    Ru = 'ru',
    En = 'en',
    Kz = 'kz',
    De = 'de'
}
/**
    * @export
    * @enum {string}
    */
export enum DoctorRoleEnum {
    Patient = 'patient',
    Doctor = 'doctor',
    Admin = 'admin',
    Manager = 'manager'
}

/**
 * 
 * @export
 * @interface DoctorNotification
 */
export interface DoctorNotification {
    /**
     * 
     * @type {string}
     * @memberof DoctorNotification
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorNotification
     */
    doctorId: string;
    /**
     * 
     * @type {Doctor}
     * @memberof DoctorNotification
     */
    doctor: Doctor;
    /**
     * 
     * @type {Patient}
     * @memberof DoctorNotification
     */
    patient: Patient;
    /**
     * 
     * @type {string}
     * @memberof DoctorNotification
     */
    patientId: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorNotification
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorNotification
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorNotification
     */
    kind: DoctorNotificationKindEnum;
    /**
     * 
     * @type {object}
     * @memberof DoctorNotification
     */
    extraData: object | null;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorNotification
     */
    isRead: boolean;
    /**
     * 
     * @type {string}
     * @memberof DoctorNotification
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorNotification
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorNotification
     */
    deletedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorNotificationKindEnum {
    UploadAnalyzesByPatients = 'uploadAnalyzesByPatients',
    CriticalIndicators = 'criticalIndicators',
    ContactMeRequest = 'contactMeRequest'
}

/**
 * 
 * @export
 * @interface Drug
 */
export interface Drug {
    /**
     * 
     * @type {string}
     * @memberof Drug
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Drug
     */
    name: string;
    /**
     * 
     * @type {Array<DrugsQuestions>}
     * @memberof Drug
     */
    drugsQuestions: Array<DrugsQuestions>;
    /**
     * 
     * @type {string}
     * @memberof Drug
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Drug
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Drug
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface DrugsQuestions
 */
export interface DrugsQuestions {
    /**
     * 
     * @type {string}
     * @memberof DrugsQuestions
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DrugsQuestions
     */
    drugId: string;
    /**
     * 
     * @type {Drug}
     * @memberof DrugsQuestions
     */
    drug?: Drug;
    /**
     * 
     * @type {string}
     * @memberof DrugsQuestions
     */
    questionId: string;
    /**
     * 
     * @type {Question}
     * @memberof DrugsQuestions
     */
    question?: Question;
    /**
     * 
     * @type {string}
     * @memberof DrugsQuestions
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof DrugsQuestions
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof DrugsQuestions
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface GetManyHospitalManagerResponseDto
 */
export interface GetManyHospitalManagerResponseDto {
    /**
     * 
     * @type {Array<HospitalManager>}
     * @memberof GetManyHospitalManagerResponseDto
     */
    data: Array<HospitalManager>;
    /**
     * 
     * @type {number}
     * @memberof GetManyHospitalManagerResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyHospitalManagerResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyHospitalManagerResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyHospitalManagerResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyHospitalResponseDto
 */
export interface GetManyHospitalResponseDto {
    /**
     * 
     * @type {Array<Hospital>}
     * @memberof GetManyHospitalResponseDto
     */
    data: Array<Hospital>;
    /**
     * 
     * @type {number}
     * @memberof GetManyHospitalResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyHospitalResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyHospitalResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyHospitalResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyPatientResponseDto
 */
export interface GetManyPatientResponseDto {
    /**
     * 
     * @type {Array<Patient>}
     * @memberof GetManyPatientResponseDto
     */
    data: Array<Patient>;
    /**
     * 
     * @type {number}
     * @memberof GetManyPatientResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPatientResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPatientResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPatientResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface Hospital
 */
export interface Hospital {
    /**
     * 
     * @type {string}
     * @memberof Hospital
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Hospital
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Hospital
     */
    patientsLimit: number;
    /**
     * 
     * @type {Array<HospitalsDoctors>}
     * @memberof Hospital
     */
    hospitalDoctors: Array<HospitalsDoctors>;
    /**
     * 
     * @type {string}
     * @memberof Hospital
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Hospital
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Hospital
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface HospitalManager
 */
export interface HospitalManager {
    /**
     * 
     * @type {string}
     * @memberof HospitalManager
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalManager
     */
    hospitalId: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalManager
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalManager
     */
    password: string;
    /**
     * 
     * @type {Hospital}
     * @memberof HospitalManager
     */
    hospital: Hospital;
    /**
     * 
     * @type {string}
     * @memberof HospitalManager
     */
    role: HospitalManagerRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof HospitalManager
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalManager
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalManager
     */
    deletedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum HospitalManagerRoleEnum {
    Patient = 'patient',
    Doctor = 'doctor',
    Admin = 'admin',
    Manager = 'manager'
}

/**
 * 
 * @export
 * @interface HospitalResponseDto
 */
export interface HospitalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof HospitalResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalResponseDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof HospitalResponseDto
     */
    patientsLimit: number;
    /**
     * 
     * @type {Array<HospitalsDoctors>}
     * @memberof HospitalResponseDto
     */
    hospitalDoctors: Array<HospitalsDoctors>;
    /**
     * 
     * @type {string}
     * @memberof HospitalResponseDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalResponseDto
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalResponseDto
     */
    deletedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof HospitalResponseDto
     */
    patientsCount: number;
    /**
     * 
     * @type {number}
     * @memberof HospitalResponseDto
     */
    invitationPatientsCount: number;
}
/**
 * 
 * @export
 * @interface HospitalsDoctors
 */
export interface HospitalsDoctors {
    /**
     * 
     * @type {string}
     * @memberof HospitalsDoctors
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalsDoctors
     */
    doctorId: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalsDoctors
     */
    hospitalId: string;
    /**
     * 
     * @type {Doctor}
     * @memberof HospitalsDoctors
     */
    doctor: Doctor;
    /**
     * 
     * @type {Hospital}
     * @memberof HospitalsDoctors
     */
    hospital: Hospital;
    /**
     * 
     * @type {string}
     * @memberof HospitalsDoctors
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalsDoctors
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalsDoctors
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    status?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    info?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    error?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    details?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    status?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    info?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    error?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    details?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface Patient
 */
export interface Patient {
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    role: PatientRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    isFirstSignUp: boolean;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    fcmToken?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    lastAuthProvider: PatientLastAuthProviderEnum;
    /**
     * 
     * @type {object}
     * @memberof Patient
     */
    notificationsSettings: object;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    language: PatientLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    inviteEndAt: string;
    /**
     * 
     * @type {PatientNotification}
     * @memberof Patient
     */
    notifications?: PatientNotification;
    /**
     * 
     * @type {PatientNotification}
     * @memberof Patient
     */
    doctorNotifications?: PatientNotification;
    /**
     * 
     * @type {PatientAnalyzes}
     * @memberof Patient
     */
    analyzes: PatientAnalyzes;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    deletedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PatientRoleEnum {
    Patient = 'patient',
    Doctor = 'doctor',
    Admin = 'admin',
    Manager = 'manager'
}
/**
    * @export
    * @enum {string}
    */
export enum PatientLastAuthProviderEnum {
    Email = 'email'
}
/**
    * @export
    * @enum {string}
    */
export enum PatientLanguageEnum {
    Ru = 'ru',
    En = 'en',
    Kz = 'kz',
    De = 'de'
}

/**
 * 
 * @export
 * @interface PatientAnalyzes
 */
export interface PatientAnalyzes {
    /**
     * 
     * @type {string}
     * @memberof PatientAnalyzes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAnalyzes
     */
    patientId: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAnalyzes
     */
    doctorId: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAnalyzes
     */
    filename: string;
    /**
     * 
     * @type {Patient}
     * @memberof PatientAnalyzes
     */
    patient: Patient;
    /**
     * 
     * @type {string}
     * @memberof PatientAnalyzes
     */
    fileKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientAnalyzes
     */
    isViewed: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientAnalyzes
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAnalyzes
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAnalyzes
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface PatientNotification
 */
export interface PatientNotification {
    /**
     * 
     * @type {string}
     * @memberof PatientNotification
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PatientNotification
     */
    patientId: string;
    /**
     * 
     * @type {Patient}
     * @memberof PatientNotification
     */
    patient: Patient;
    /**
     * 
     * @type {string}
     * @memberof PatientNotification
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PatientNotification
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof PatientNotification
     */
    kind: PatientNotificationKindEnum;
    /**
     * 
     * @type {object}
     * @memberof PatientNotification
     */
    extraData: object | null;
    /**
     * 
     * @type {Doctor}
     * @memberof PatientNotification
     */
    doctor: Doctor;
    /**
     * 
     * @type {string}
     * @memberof PatientNotification
     */
    doctorId: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientNotification
     */
    isRead: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientNotification
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PatientNotification
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof PatientNotification
     */
    deletedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PatientNotificationKindEnum {
    NewSurvey = 'newSurvey'
}

/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    id: string;
    /**
     * 
     * @type {Doctor}
     * @memberof Question
     */
    doctor?: Doctor;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    doctorId: string | null;
    /**
     * 
     * @type {Array<QuestionCategoryQuestion>}
     * @memberof Question
     */
    questionCategoryQuestions?: Array<QuestionCategoryQuestion>;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    type: QuestionTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof Question
     */
    indicators: object | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    isCustom: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    isActual: boolean;
    /**
     * 
     * @type {Array<QuestionOption>}
     * @memberof Question
     */
    questionOptions: Array<QuestionOption>;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    deletedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionTypeEnum {
    Radio = 'radio',
    Checkbox = 'checkbox',
    Numeric = 'numeric',
    Scale = 'scale',
    Pressure = 'pressure',
    Temperature = 'temperature',
    Weight = 'weight',
    Pulse = 'pulse'
}

/**
 * 
 * @export
 * @interface QuestionCategory
 */
export interface QuestionCategory {
    /**
     * 
     * @type {string}
     * @memberof QuestionCategory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategory
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategory
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategory
     */
    name: string;
    /**
     * 
     * @type {Array<QuestionCategoryQuestion>}
     * @memberof QuestionCategory
     */
    questionCategoryQuestions: Array<QuestionCategoryQuestion>;
}
/**
 * 
 * @export
 * @interface QuestionCategoryQuestion
 */
export interface QuestionCategoryQuestion {
    /**
     * 
     * @type {string}
     * @memberof QuestionCategoryQuestion
     */
    id: string;
    /**
     * 
     * @type {Question}
     * @memberof QuestionCategoryQuestion
     */
    question?: Question;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategoryQuestion
     */
    questionId: string;
    /**
     * 
     * @type {QuestionCategory}
     * @memberof QuestionCategoryQuestion
     */
    questionCategory?: QuestionCategory;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategoryQuestion
     */
    questionCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategoryQuestion
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategoryQuestion
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategoryQuestion
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface QuestionOption
 */
export interface QuestionOption {
    /**
     * 
     * @type {string}
     * @memberof QuestionOption
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionOption
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionOption
     */
    index: number;
    /**
     * 
     * @type {Question}
     * @memberof QuestionOption
     */
    question?: Question;
    /**
     * 
     * @type {string}
     * @memberof QuestionOption
     */
    questionId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionOption
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionOption
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionOption
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface RefreshTokenDto
 */
export interface RefreshTokenDto {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenDto
     */
    refreshToken: string;
}
/**
 * 
 * @export
 * @interface UpdateHospitalManagerDto
 */
export interface UpdateHospitalManagerDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateHospitalManagerDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHospitalManagerDto
     */
    hospitalId: string;
}

/**
 * AdminPanelDrugsXlsApi - axios parameter creator
 * @export
 */
export const AdminPanelDrugsXlsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Parse drugs from xlsm file
         * @param {any} [drugs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drugsXlsControllerParse: async (drugs?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/drugs-xls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (drugs !== undefined) { 
                localVarFormParams.append('drugs', drugs as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelDrugsXlsApi - functional programming interface
 * @export
 */
export const AdminPanelDrugsXlsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelDrugsXlsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Parse drugs from xlsm file
         * @param {any} [drugs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drugsXlsControllerParse(drugs?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Drug>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drugsXlsControllerParse(drugs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelDrugsXlsApi - factory interface
 * @export
 */
export const AdminPanelDrugsXlsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelDrugsXlsApiFp(configuration)
    return {
        /**
         * 
         * @summary Parse drugs from xlsm file
         * @param {any} [drugs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drugsXlsControllerParse(drugs?: any, options?: any): AxiosPromise<Array<Drug>> {
            return localVarFp.drugsXlsControllerParse(drugs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelDrugsXlsApi - object-oriented interface
 * @export
 * @class AdminPanelDrugsXlsApi
 * @extends {BaseAPI}
 */
export class AdminPanelDrugsXlsApi extends BaseAPI {
    /**
     * 
     * @summary Parse drugs from xlsm file
     * @param {any} [drugs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelDrugsXlsApi
     */
    public drugsXlsControllerParse(drugs?: any, options?: any) {
        return AdminPanelDrugsXlsApiFp(this.configuration).drugsXlsControllerParse(drugs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelHospitalManagersApi - axios parameter creator
 * @export
 */
export const AdminPanelHospitalManagersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send code for sign-up
         * @param {CreateHospitalManagerDto} createHospitalManagerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseHospitalManagersCrudControllerHospitalManager: async (createHospitalManagerDto: CreateHospitalManagerDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHospitalManagerDto' is not null or undefined
            assertParamExists('createOneBaseHospitalManagersCrudControllerHospitalManager', 'createHospitalManagerDto', createHospitalManagerDto)
            const localVarPath = `/admin/hospital-managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHospitalManagerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single HospitalManager
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseHospitalManagersCrudControllerHospitalManager: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseHospitalManagersCrudControllerHospitalManager', 'id', id)
            const localVarPath = `/admin/hospital-managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple HospitalManagers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseHospitalManagersCrudControllerHospitalManager: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/hospital-managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single HospitalManager
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseHospitalManagersCrudControllerHospitalManager: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseHospitalManagersCrudControllerHospitalManager', 'id', id)
            const localVarPath = `/admin/hospital-managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single HospitalManager
         * @param {string} id 
         * @param {UpdateHospitalManagerDto} updateHospitalManagerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseHospitalManagersCrudControllerHospitalManager: async (id: string, updateHospitalManagerDto: UpdateHospitalManagerDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseHospitalManagersCrudControllerHospitalManager', 'id', id)
            // verify required parameter 'updateHospitalManagerDto' is not null or undefined
            assertParamExists('updateOneBaseHospitalManagersCrudControllerHospitalManager', 'updateHospitalManagerDto', updateHospitalManagerDto)
            const localVarPath = `/admin/hospital-managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHospitalManagerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelHospitalManagersApi - functional programming interface
 * @export
 */
export const AdminPanelHospitalManagersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelHospitalManagersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send code for sign-up
         * @param {CreateHospitalManagerDto} createHospitalManagerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseHospitalManagersCrudControllerHospitalManager(createHospitalManagerDto: CreateHospitalManagerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseHospitalManagersCrudControllerHospitalManager(createHospitalManagerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single HospitalManager
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseHospitalManagersCrudControllerHospitalManager(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseHospitalManagersCrudControllerHospitalManager(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple HospitalManagers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseHospitalManagersCrudControllerHospitalManager(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyHospitalManagerResponseDto | Array<HospitalManager>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseHospitalManagersCrudControllerHospitalManager(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single HospitalManager
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseHospitalManagersCrudControllerHospitalManager(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HospitalManager>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseHospitalManagersCrudControllerHospitalManager(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single HospitalManager
         * @param {string} id 
         * @param {UpdateHospitalManagerDto} updateHospitalManagerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseHospitalManagersCrudControllerHospitalManager(id: string, updateHospitalManagerDto: UpdateHospitalManagerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HospitalManager>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseHospitalManagersCrudControllerHospitalManager(id, updateHospitalManagerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelHospitalManagersApi - factory interface
 * @export
 */
export const AdminPanelHospitalManagersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelHospitalManagersApiFp(configuration)
    return {
        /**
         * 
         * @summary Send code for sign-up
         * @param {CreateHospitalManagerDto} createHospitalManagerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseHospitalManagersCrudControllerHospitalManager(createHospitalManagerDto: CreateHospitalManagerDto, options?: any): AxiosPromise<void> {
            return localVarFp.createOneBaseHospitalManagersCrudControllerHospitalManager(createHospitalManagerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single HospitalManager
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseHospitalManagersCrudControllerHospitalManager(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseHospitalManagersCrudControllerHospitalManager(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple HospitalManagers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseHospitalManagersCrudControllerHospitalManager(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyHospitalManagerResponseDto | Array<HospitalManager>> {
            return localVarFp.getManyBaseHospitalManagersCrudControllerHospitalManager(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single HospitalManager
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseHospitalManagersCrudControllerHospitalManager(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<HospitalManager> {
            return localVarFp.getOneBaseHospitalManagersCrudControllerHospitalManager(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single HospitalManager
         * @param {string} id 
         * @param {UpdateHospitalManagerDto} updateHospitalManagerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseHospitalManagersCrudControllerHospitalManager(id: string, updateHospitalManagerDto: UpdateHospitalManagerDto, options?: any): AxiosPromise<HospitalManager> {
            return localVarFp.updateOneBaseHospitalManagersCrudControllerHospitalManager(id, updateHospitalManagerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelHospitalManagersApi - object-oriented interface
 * @export
 * @class AdminPanelHospitalManagersApi
 * @extends {BaseAPI}
 */
export class AdminPanelHospitalManagersApi extends BaseAPI {
    /**
     * 
     * @summary Send code for sign-up
     * @param {CreateHospitalManagerDto} createHospitalManagerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelHospitalManagersApi
     */
    public createOneBaseHospitalManagersCrudControllerHospitalManager(createHospitalManagerDto: CreateHospitalManagerDto, options?: any) {
        return AdminPanelHospitalManagersApiFp(this.configuration).createOneBaseHospitalManagersCrudControllerHospitalManager(createHospitalManagerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single HospitalManager
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelHospitalManagersApi
     */
    public deleteOneBaseHospitalManagersCrudControllerHospitalManager(id: string, options?: any) {
        return AdminPanelHospitalManagersApiFp(this.configuration).deleteOneBaseHospitalManagersCrudControllerHospitalManager(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple HospitalManagers
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelHospitalManagersApi
     */
    public getManyBaseHospitalManagersCrudControllerHospitalManager(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return AdminPanelHospitalManagersApiFp(this.configuration).getManyBaseHospitalManagersCrudControllerHospitalManager(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single HospitalManager
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelHospitalManagersApi
     */
    public getOneBaseHospitalManagersCrudControllerHospitalManager(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return AdminPanelHospitalManagersApiFp(this.configuration).getOneBaseHospitalManagersCrudControllerHospitalManager(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single HospitalManager
     * @param {string} id 
     * @param {UpdateHospitalManagerDto} updateHospitalManagerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelHospitalManagersApi
     */
    public updateOneBaseHospitalManagersCrudControllerHospitalManager(id: string, updateHospitalManagerDto: UpdateHospitalManagerDto, options?: any) {
        return AdminPanelHospitalManagersApiFp(this.configuration).updateOneBaseHospitalManagersCrudControllerHospitalManager(id, updateHospitalManagerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelHospitalsApi - axios parameter creator
 * @export
 */
export const AdminPanelHospitalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single Hospital
         * @param {CreateHospitalDto} createHospitalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseHospitalsCrudControllerHospital: async (createHospitalDto: CreateHospitalDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHospitalDto' is not null or undefined
            assertParamExists('createOneBaseHospitalsCrudControllerHospital', 'createHospitalDto', createHospitalDto)
            const localVarPath = `/admin/hospitals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHospitalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete hospital with doctors
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseHospitalsCrudControllerHospital: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseHospitalsCrudControllerHospital', 'id', id)
            const localVarPath = `/admin/hospitals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many hospitals
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseHospitalsCrudControllerHospital: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/hospitals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get hospital by id
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseHospitalsCrudControllerHospital: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseHospitalsCrudControllerHospital', 'id', id)
            const localVarPath = `/admin/hospitals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Hospital
         * @param {string} id 
         * @param {CreateHospitalDto} createHospitalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseHospitalsCrudControllerHospital: async (id: string, createHospitalDto: CreateHospitalDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseHospitalsCrudControllerHospital', 'id', id)
            // verify required parameter 'createHospitalDto' is not null or undefined
            assertParamExists('updateOneBaseHospitalsCrudControllerHospital', 'createHospitalDto', createHospitalDto)
            const localVarPath = `/admin/hospitals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHospitalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelHospitalsApi - functional programming interface
 * @export
 */
export const AdminPanelHospitalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelHospitalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single Hospital
         * @param {CreateHospitalDto} createHospitalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseHospitalsCrudControllerHospital(createHospitalDto: CreateHospitalDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Hospital>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseHospitalsCrudControllerHospital(createHospitalDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete hospital with doctors
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseHospitalsCrudControllerHospital(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseHospitalsCrudControllerHospital(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many hospitals
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseHospitalsCrudControllerHospital(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseHospitalsCrudControllerHospital(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get hospital by id
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseHospitalsCrudControllerHospital(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HospitalResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseHospitalsCrudControllerHospital(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Hospital
         * @param {string} id 
         * @param {CreateHospitalDto} createHospitalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseHospitalsCrudControllerHospital(id: string, createHospitalDto: CreateHospitalDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Hospital>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseHospitalsCrudControllerHospital(id, createHospitalDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelHospitalsApi - factory interface
 * @export
 */
export const AdminPanelHospitalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelHospitalsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single Hospital
         * @param {CreateHospitalDto} createHospitalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseHospitalsCrudControllerHospital(createHospitalDto: CreateHospitalDto, options?: any): AxiosPromise<Hospital> {
            return localVarFp.createOneBaseHospitalsCrudControllerHospital(createHospitalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete hospital with doctors
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseHospitalsCrudControllerHospital(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteOneBaseHospitalsCrudControllerHospital(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many hospitals
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseHospitalsCrudControllerHospital(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<object> {
            return localVarFp.getManyBaseHospitalsCrudControllerHospital(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get hospital by id
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseHospitalsCrudControllerHospital(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<HospitalResponseDto> {
            return localVarFp.getOneBaseHospitalsCrudControllerHospital(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Hospital
         * @param {string} id 
         * @param {CreateHospitalDto} createHospitalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseHospitalsCrudControllerHospital(id: string, createHospitalDto: CreateHospitalDto, options?: any): AxiosPromise<Hospital> {
            return localVarFp.updateOneBaseHospitalsCrudControllerHospital(id, createHospitalDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelHospitalsApi - object-oriented interface
 * @export
 * @class AdminPanelHospitalsApi
 * @extends {BaseAPI}
 */
export class AdminPanelHospitalsApi extends BaseAPI {
    /**
     * 
     * @summary Create a single Hospital
     * @param {CreateHospitalDto} createHospitalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelHospitalsApi
     */
    public createOneBaseHospitalsCrudControllerHospital(createHospitalDto: CreateHospitalDto, options?: any) {
        return AdminPanelHospitalsApiFp(this.configuration).createOneBaseHospitalsCrudControllerHospital(createHospitalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete hospital with doctors
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelHospitalsApi
     */
    public deleteOneBaseHospitalsCrudControllerHospital(id: string, options?: any) {
        return AdminPanelHospitalsApiFp(this.configuration).deleteOneBaseHospitalsCrudControllerHospital(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many hospitals
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelHospitalsApi
     */
    public getManyBaseHospitalsCrudControllerHospital(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return AdminPanelHospitalsApiFp(this.configuration).getManyBaseHospitalsCrudControllerHospital(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get hospital by id
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelHospitalsApi
     */
    public getOneBaseHospitalsCrudControllerHospital(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return AdminPanelHospitalsApiFp(this.configuration).getOneBaseHospitalsCrudControllerHospital(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Hospital
     * @param {string} id 
     * @param {CreateHospitalDto} createHospitalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelHospitalsApi
     */
    public updateOneBaseHospitalsCrudControllerHospital(id: string, createHospitalDto: CreateHospitalDto, options?: any) {
        return AdminPanelHospitalsApiFp(this.configuration).updateOneBaseHospitalsCrudControllerHospital(id, createHospitalDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelPatientsApi - axios parameter creator
 * @export
 */
export const AdminPanelPatientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple Patients
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBasePatientsCrudControllerPatient: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Patient
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBasePatientsCrudControllerPatient: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBasePatientsCrudControllerPatient', 'id', id)
            const localVarPath = `/admin/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelPatientsApi - functional programming interface
 * @export
 */
export const AdminPanelPatientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelPatientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Patients
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBasePatientsCrudControllerPatient(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyPatientResponseDto | Array<Patient>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBasePatientsCrudControllerPatient(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Patient
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBasePatientsCrudControllerPatient(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBasePatientsCrudControllerPatient(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelPatientsApi - factory interface
 * @export
 */
export const AdminPanelPatientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelPatientsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Patients
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBasePatientsCrudControllerPatient(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyPatientResponseDto | Array<Patient>> {
            return localVarFp.getManyBasePatientsCrudControllerPatient(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Patient
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBasePatientsCrudControllerPatient(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Patient> {
            return localVarFp.getOneBasePatientsCrudControllerPatient(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelPatientsApi - object-oriented interface
 * @export
 * @class AdminPanelPatientsApi
 * @extends {BaseAPI}
 */
export class AdminPanelPatientsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple Patients
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelPatientsApi
     */
    public getManyBasePatientsCrudControllerPatient(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return AdminPanelPatientsApiFp(this.configuration).getManyBasePatientsCrudControllerPatient(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Patient
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelPatientsApi
     */
    public getOneBasePatientsCrudControllerPatient(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return AdminPanelPatientsApiFp(this.configuration).getOneBasePatientsCrudControllerPatient(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApi: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHello(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheckApi(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheckApi(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello(options?: any): AxiosPromise<string> {
            return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApi(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.healthControllerCheckApi(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHello(options?: any) {
        return DefaultApiFp(this.configuration).appControllerGetHello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheckApi(options?: any) {
        return DefaultApiFp(this.configuration).healthControllerCheckApi(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RefreshTokenApi - axios parameter creator
 * @export
 */
export const RefreshTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get new access/refresh based on refresh
         * @param {RefreshTokenDto} refreshTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshAccessToken: async (refreshTokenDto: RefreshTokenDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenDto' is not null or undefined
            assertParamExists('authControllerRefreshAccessToken', 'refreshTokenDto', refreshTokenDto)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefreshTokenApi - functional programming interface
 * @export
 */
export const RefreshTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RefreshTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get new access/refresh based on refresh
         * @param {RefreshTokenDto} refreshTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefreshAccessToken(refreshTokenDto: RefreshTokenDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRefreshAccessToken(refreshTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RefreshTokenApi - factory interface
 * @export
 */
export const RefreshTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RefreshTokenApiFp(configuration)
    return {
        /**
         * 
         * @summary Get new access/refresh based on refresh
         * @param {RefreshTokenDto} refreshTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshAccessToken(refreshTokenDto: RefreshTokenDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerRefreshAccessToken(refreshTokenDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RefreshTokenApi - object-oriented interface
 * @export
 * @class RefreshTokenApi
 * @extends {BaseAPI}
 */
export class RefreshTokenApi extends BaseAPI {
    /**
     * 
     * @summary Get new access/refresh based on refresh
     * @param {RefreshTokenDto} refreshTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefreshTokenApi
     */
    public authControllerRefreshAccessToken(refreshTokenDto: RefreshTokenDto, options?: any) {
        return RefreshTokenApiFp(this.configuration).authControllerRefreshAccessToken(refreshTokenDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminsApi - axios parameter creator
 * @export
 */
export const V1AdminsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check admin email for uniqueness
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerEmailIsUniq: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('adminsControllerEmailIsUniq', 'email', email)
            const localVarPath = `/v1/admins/email-is-uniq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve current admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerGetMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admins/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign in via email/password
         * @param {AdminsSignInBodyDto} adminsSignInBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerSignIn: async (adminsSignInBodyDto: AdminsSignInBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminsSignInBodyDto' is not null or undefined
            assertParamExists('adminsControllerSignIn', 'adminsSignInBodyDto', adminsSignInBodyDto)
            const localVarPath = `/v1/admins/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminsSignInBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminsApi - functional programming interface
 * @export
 */
export const V1AdminsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check admin email for uniqueness
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminsControllerEmailIsUniq(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminsControllerEmailIsUniq(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve current admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminsControllerGetMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminsControllerGetMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign in via email/password
         * @param {AdminsSignInBodyDto} adminsSignInBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminsControllerSignIn(adminsSignInBodyDto: AdminsSignInBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminsControllerSignIn(adminsSignInBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminsApi - factory interface
 * @export
 */
export const V1AdminsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminsApiFp(configuration)
    return {
        /**
         * 
         * @summary Check admin email for uniqueness
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerEmailIsUniq(email: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminsControllerEmailIsUniq(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve current admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerGetMe(options?: any): AxiosPromise<AdminDto> {
            return localVarFp.adminsControllerGetMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign in via email/password
         * @param {AdminsSignInBodyDto} adminsSignInBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerSignIn(adminsSignInBodyDto: AdminsSignInBodyDto, options?: any): AxiosPromise<AuthAdminDto> {
            return localVarFp.adminsControllerSignIn(adminsSignInBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminsApi - object-oriented interface
 * @export
 * @class V1AdminsApi
 * @extends {BaseAPI}
 */
export class V1AdminsApi extends BaseAPI {
    /**
     * 
     * @summary Check admin email for uniqueness
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminsApi
     */
    public adminsControllerEmailIsUniq(email: string, options?: any) {
        return V1AdminsApiFp(this.configuration).adminsControllerEmailIsUniq(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve current admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminsApi
     */
    public adminsControllerGetMe(options?: any) {
        return V1AdminsApiFp(this.configuration).adminsControllerGetMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign in via email/password
     * @param {AdminsSignInBodyDto} adminsSignInBodyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminsApi
     */
    public adminsControllerSignIn(adminsSignInBodyDto: AdminsSignInBodyDto, options?: any) {
        return V1AdminsApiFp(this.configuration).adminsControllerSignIn(adminsSignInBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


