import Axios, { AxiosError, AxiosInstance } from 'axios';
import { BASE_API_URL } from 'utils/constants';

export class Http {
  constructor(private readonly _axios: AxiosInstance) {
    this.useInterceptors();
  }

  private useInterceptors(): void {
    this._axios.interceptors.response.use(undefined, (error: AxiosError): Promise<AxiosError> => {
      return Promise.reject(error.response?.data);
    });
  }

  get get() {
    return this._axios.get;
  }

  get post() {
    return this._axios.post;
  }

  get put() {
    return this._axios.put;
  }

  get patch() {
    return this._axios.patch;
  }
  get delete() {
    return this._axios.delete;
  }

  get request() {
    return this._axios.request;
  }

  get axios(): AxiosInstance {
    return this._axios;
  }
}

export const http = new Http(
  Axios.create({
    baseURL: BASE_API_URL,
    timeout: 60000,
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  }),
);
