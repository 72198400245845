import { Create, ReferenceInput, SelectInput, SimpleForm, TextInput, email, required } from 'react-admin';

import { BackButton } from '../../../ui';
import { Resources } from '../../types';

const validateEmail = [required(), email()];
const validateRequired = required();

const ManagerCreate = ({ ...props }) => {
  return (
    <Create {...props} title="Добавьте менеджера" actions={<BackButton />}>
      <SimpleForm>
        <TextInput source="email" label="E-mail" validate={validateEmail} />
        <ReferenceInput
          label="Наименование учреждения"
          source="hospitalId"
          reference={Resources.HOSPITALS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={10000}
          validate={validateRequired}>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default ManagerCreate;
