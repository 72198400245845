import { MenuItemLink, MenuProps, ReduxState, getResources } from 'react-admin';
import React, { FC } from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Resources } from 'routes/types';
import { createElement } from 'react';
import { useSelector } from 'react-redux';

const Menu: FC<MenuProps> = ({ onMenuClick }) => {
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources) as Array<any>;
  return (
    <div>
      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={(resource.options && resource.options.label) || resource.name}
          leftIcon={createElement(resource.icon)}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      <MenuItemLink
        to={`/${Resources.DRUGS}`}
        primaryText="Препараты"
        leftIcon={<AddCircleIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
    </div>
  );
};

export default Menu;
