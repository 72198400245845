import { CreateButton } from 'react-admin';
import React from 'react';
import styled from 'styled-components';

const HospitalListEmpty = () => {
  return (
    <Root>
      <Text>У Вас пока нет медицинских учреждений. Пожалуйста, добавьте новое учреждение</Text>
      <CreateButton label="Добавить новое учреждение" />
    </Root>
  );
};

export default HospitalListEmpty;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Text = styled.div`
  max-width: 350px;
  padding: 30px;
  text-align: center;
  line-height: 30px;
`;
